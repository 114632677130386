import React from 'react';
import { useSiteMap } from '@unisporkal/sites';
import { useTranslations, useLocale } from '@unisporkal/localization';
import { Link } from '@unisporkal/alliance-ui-link';
import styles from '../FullFooter.module.scss';

const Content = () => {
  const t = useTranslations();
  const siteMap = useSiteMap();
  const locale = useLocale();

  return (
    <div data-testid="content">
      <Link
        className={styles.link}
        data-nav="footer_content_creative"
        data-ui-location="footer"
        href={siteMap.creativeSolutions()}
        target="_self"
      >
        {t('royalty_free_creative')}
      </Link>
      <Link
        className={styles.link}
        data-nav="footer_content_video"
        data-ui-location="footer"
        href={siteMap.creativeVideo()}
        target="_self"
      >
        {t('video')}
      </Link>
      <Link
        className={styles.link}
        data-nav="footer_content_editorial"
        data-ui-location="footer"
        href={siteMap.editorialImages()}
        target="_self"
      >
        {t('editorial')}
      </Link>
      <Link
        className={styles.link}
        data-nav="footer_content_editorial_archival"
        data-ui-location="footer"
        href={siteMap.editorialImagesArchival()}
        target="_self"
      >
        {t('archive')}
      </Link>
      <Link
        className={styles.link}
        data-nav="footer_content_custom_content"
        data-ui-location="footer"
        href={siteMap.customContent()}
        target="_self"
      >
        {t('custom_content')}
      </Link>
      <Link
        className={styles.link}
        data-nav="footer_content_creative_collections"
        data-ui-location="footer"
        href={siteMap.collectionsUri()}
        target="_self"
      >
        {t('creative_collections')}
      </Link>
      <Link
        className={styles.link}
        data-nav="footer_content_contributor_support"
        data-ui-location="footer"
        href={siteMap.contributorSupport()}
        target="_self"
      >
        {t('contributor_support')}
      </Link>
      <Link
        className={styles.link}
        data-nav="footer_company_applyto_be_contributor"
        data-ui-location="footer"
        href={siteMap.workWithUs()}
      >
        {t('apply_to_be_contributor')}
      </Link>
      {locale === 'en-us' || locale === 'en-gb' ? (
        <>
          <Link
            className={styles.link}
            data-nav="footer_stock_photos"
            data-ui-location="footer"
            data-testid="stock-photos-link"
            href={siteMap.creativeImagesStockPhotos()}
          >
            {t('photos_ftlp')}
          </Link>
          <Link
            className={styles.link}
            data-nav="footer_stock_videos"
            data-ui-location="footer"
            data-testid="stock-videos-link"
            href={siteMap.creativeVideoStockVideos()}
          >
            {t('videos_ftlp')}
          </Link>
        </>
      ) : null}
    </div>
  );
};

export default Content;
