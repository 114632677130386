import React from 'react';
import { useSiteMap } from '@unisporkal/sites';
import { useTranslations } from '@unisporkal/localization';
import { Link } from '@unisporkal/alliance-ui-link';
import styles from '../FullFooter.module.scss';

const Solutions = () => {
  const t = useTranslations();
  const siteMap = useSiteMap();
  return (
    <div data-testid="solutions">
      <Link
        className={styles.link}
        data-nav="foot_solutions_overview"
        data-ui-location="footer"
        href={siteMap.cleanPath(siteMap.enterprise())}
        target="_self"
      >
        {t('overview')}
      </Link>
      <Link
        className={styles.link}
        data-nav="foot_solutions_pricing"
        data-ui-location="footer"
        href={siteMap.plansAndPricing()}
        target="_self"
      >
        {t('plans_and_pricing')}
      </Link>
      <Link
        className={styles.link}
        data-nav="foot_solutions_premium_access"
        data-ui-location="footer"
        href={siteMap.premiumAccess()}
        target="_self"
      >
        {t('premium_access')}
      </Link>
      <Link
        className={styles.link}
        data-nav="foot_solutions_custom_solutions"
        data-ui-location="footer"
        href={siteMap.customSolutions()}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('assignments')}
      </Link>
    </div>
  );
};

export default Solutions;
