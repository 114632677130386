/* eslint-disable import/no-unresolved */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@unisporkal/localization';
import FooterLinks from './FooterLinks/FooterLinks';
import Bottom from './FooterLinks/Bottom';
import CountryPicker from '../CountryPicker/CountryPicker';
import styles from './FullFooter.module.scss';
import Logo from '../icons/g_logo.svg?icon';

const FullFooter = ({ showEuLegalLink, showUSPrivacyLink }) => {
  const t = useTranslations();
  return (
    <footer
      id="global-nav-footer"
      data-testid="full-layout-footer"
      className={styles.fullFooter}
    >
      <div className={styles.mainFooter}>
        <div className={styles.primary}>
          <div className={`${styles.mobileLogo}`}>
            <a
              className={styles.link}
              data-nav="foot_general_home"
              data-ui-location="footer"
              href="/"
              target="_self"
            >
              <div className={styles.icon}>
                <Logo />
              </div>
            </a>
          </div>
          <div className={`${styles.primaryGroup} ${styles.logo}`}>
            <a
              className={styles.link}
              data-nav="foot_general_home"
              data-ui-location="footer"
              href="/"
              target="_self"
            >
              <div className={`${styles.icon} ${styles.desktopLogo}`}>
                <Logo />
              </div>
            </a>
            <CountryPicker />
          </div>
          <FooterLinks />
        </div>
        <div className={styles.secondary}>
          <div className={styles.copyright}>
            {t('legal_trademark', { current_year: new Date().getFullYear() })}
          </div>
          <div className={styles.secondaryGroup}>
            <Bottom
              showEuLegalLink={showEuLegalLink}
              showUSPrivacyLink={showUSPrivacyLink}
            />
          </div>
        </div>
      </div>
    </footer>
  );
};

FullFooter.propTypes = {
  showEuLegalLink: PropTypes.bool.isRequired,
  showUSPrivacyLink: PropTypes.bool.isRequired,
};

export default FullFooter;
