/* eslint-disable import/no-unresolved */
import React, { Suspense, useMemo, useState } from 'react';
import { lazyComponent } from '@unisporkal/react-lazy-component';
import { useCookieCutter } from '@unisporkal/react-cookie-cutter';
import { useSite } from '@unisporkal/sites';
import { IconButton } from '@unisporkal/alliance-ui-icon-button';
import styles from './CountryPicker.module.scss';
import ArrowRight from '../icons/arrow_right.svg?icon';
import GlobalizationCookie from '../../../cookies/GlobalizationCookie.ts';
import gettyCountryLanguages from '../../../FooterData/getty.json';

const CountryPickerModal = lazyComponent(() => import('./CountryPickerModal'));

const Flag = lazyComponent((props) =>
  import(`../../Flags/${props.countrycode}.svg?icon`)
);

const countryInfo = (countryKeyPairs, tld, language) => {
  for (const [countryCode, regions] of countryKeyPairs) {
    for (const [, region] of Object.entries(regions)) {
      if (
        !region.masterDelegate &&
        region.href.endsWith(tld) &&
        // if language cookie is set, display the correct region locale
        // if language cookie is not set, display the first region that matches the tld
        (region.locale === language || !language)
      ) {
        return {
          code: countryCode,
          name: region.name,
        };
      }
    }
  }

  return {
    code: 'USA',
    name: 'United States',
  };
};

const CountryPicker = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { tld } = useSite();
  const countryKeyPairs = Object.entries(gettyCountryLanguages);
  const { language } = useCookieCutter(GlobalizationCookie);
  const country = useMemo(
    () => countryInfo(countryKeyPairs, tld, language),
    [countryKeyPairs, tld, language]
  );

  const onPickerClick = () => {
    setIsModalOpen(true);
  };

  const onModalClose = (e) => {
    e.stopPropagation();
    setIsModalOpen(false);
  };
  return (
    <>
      <IconButton
        tabIndex={0}
        ariaLabel="Region Picker"
        className={styles.btn}
        data-nav="foot_region_picker"
        data-ui-location="footer"
        target="region_picker"
        onClick={onPickerClick}
        data-testid="country-picker"
      >
        <span className={styles.country}>
          <span className={styles.countryFlag}>
            <Flag countrycode={country.code} />
          </span>
          <span className={styles.countryName}>{country.name}</span>
        </span>
        <span className={styles.arrowIcon}>
          <ArrowRight />
        </span>
      </IconButton>
      {isModalOpen ? (
        <Suspense>
          <CountryPickerModal
            open={isModalOpen}
            currentRegionName={country.name}
            countryKeyPairs={countryKeyPairs}
            onClose={onModalClose}
          />
        </Suspense>
      ) : null}
    </>
  );
};

export default CountryPicker;
