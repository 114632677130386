import CookieCutter from '@unisporkal/cookie-cutter';

export type GlobalizationCookieValues = {
  language?: string;
};

const GlobalizationCookie = CookieCutter.define<GlobalizationCookieValues>({
  name: 'tg',
  options: {
    maxAgeYears: 5,
  },
  values: {
    language: 'language',
  },
});

export default GlobalizationCookie;
