import React from 'react';
import PropTypes from 'prop-types';
import { useSiteMap } from '@unisporkal/sites';
import { useTranslations } from '@unisporkal/localization';
import styles from './MinimalFooter.module.scss';

const MinimalFooter = ({ showEuLegalLink, showUSPrivacyLink }) => {
  const siteMap = useSiteMap();
  const t = useTranslations();

  return (
    <footer
      data-testid="minimal-layout-footer"
      className={styles.minimalFooterContainer}
    >
      <div className={styles.minimalFooter}>
        <div className={styles.secondary}>
          <div className={styles.copyright}>
            {t('legal_trademark', { current_year: new Date().getFullYear() })}
          </div>
          <div className={styles.secondaryGroupMinimal}>
            <a
              className={styles.secondaryGroupItem}
              data-nav="foot_general_license_information"
              data-ui-location="footer"
              href={siteMap.licenseOverview()}
              target="license_info"
              rel="nofollow"
            >
              {t('license_information')}
            </a>
            {showEuLegalLink ? (
              <a
                className={styles.secondaryGroupItem}
                data-nav="foot_general_legal_information"
                data-ui-location="footer"
                href={siteMap.euLegal()}
                target="eu_legal"
                rel="nofollow"
                data-testid="eu-legal"
              >
                {t('EULegal')}
              </a>
            ) : null}
            <a
              className={styles.secondaryGroupItem}
              data-nav="foot_general_privacy_policy"
              data-ui-location="footer"
              href={siteMap.privacyPolicy()}
              target="private_policy"
              rel="nofollow"
            >
              {t('privacy_policy')}
            </a>
            {showUSPrivacyLink ? (
              <a
                data-testid="us-legal-privacy"
                className={styles.secondaryGroupItem}
                data-nav="foot_general_legal_information"
                data-ui-location="footer"
                href={siteMap.privacyPolicy()}
                target="privacy_policy"
                rel="nofollow"
              >
                {t('do_not_sell_or_share_information')}
              </a>
            ) : null}
            <a
              className={styles.secondaryGroupItem}
              data-nav="foot_general_help_center"
              data-ui-location="footer"
              href={siteMap.customerSupport()}
              target="contact_us"
            >
              {t('help_center')}
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

MinimalFooter.propTypes = {
  showEuLegalLink: PropTypes.bool.isRequired,
  showUSPrivacyLink: PropTypes.bool.isRequired,
};

export default MinimalFooter;
