/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { useTranslations } from '@unisporkal/localization';
import { IconButton } from '@unisporkal/alliance-ui-icon-button';
import Content from './Content';
import Solutions from './Solutions';
import Services from './Services';
import Company from './Company';
import Social from './Social';
import styles from '../FullFooter.module.scss';

import PlusIcon from '../../icons/plus.svg?icon';
import MinusIcon from '../../icons/minus.svg?icon';

const expandIcon = (open) =>
  open ? (
    <MinusIcon
      className={`${styles.icon} ${styles.headingIcon} ${styles.minusIcon}`}
    />
  ) : (
    <PlusIcon
      className={`${styles.icon} ${styles.headingIcon} ${styles.plusIcon}`}
    />
  );

const FooterLinks = () => {
  const t = useTranslations();
  const [contentOpen, contentSetOpen] = useState(false);
  const [solutionsOpen, solutionsSetOpen] = useState(false);
  const [productsOpen, productsSetOpen] = useState(false);
  const [companyOpen, companySetOpen] = useState(false);

  return (
    <div
      className={`${styles.primaryGroup} ${styles.links}`}
      data-testid="footer-links"
    >
      <div>
        <IconButton
          tabIndex={0}
          className={styles.primaryGroupHeading}
          onClick={() => contentSetOpen(!contentOpen)}
          ariaLabel="Accordion Content Links"
          data-testid="content-links"
        >
          {t('content_caps')}
          {expandIcon(contentOpen)}
        </IconButton>
        <div
          className={`${styles.items} ${contentOpen ? styles.activeItems : ''}`}
        >
          <Content />
        </div>
      </div>
      <div>
        <IconButton
          tabIndex={0}
          className={styles.primaryGroupHeading}
          onClick={() => solutionsSetOpen(!solutionsOpen)}
          ariaLabel="Accordion Solutions Links"
          data-testid="solutions-links"
        >
          {t('solutions_caps')}
          {expandIcon(solutionsOpen)}
        </IconButton>
        <div
          className={`${styles.items} ${
            solutionsOpen ? styles.activeItems : ''
          }`}
        >
          <Solutions />
        </div>
      </div>
      <div>
        <IconButton
          tabIndex={0}
          className={styles.primaryGroupHeading}
          onClick={() => productsSetOpen(!productsOpen)}
          ariaLabel="Accordion Tools and Services Links"
          data-testid="tools-services-links"
        >
          {t('tools_and_services_caps')}
          {expandIcon(productsOpen)}
        </IconButton>
        <div
          className={`${styles.items} ${
            productsOpen ? styles.activeItems : ''
          }`}
        >
          <Services />
        </div>
      </div>
      <div>
        <IconButton
          tabIndex={0}
          className={styles.primaryGroupHeading}
          onClick={() => companySetOpen(!companyOpen)}
          ariaLabel="Accordion Company Links"
          data-testid="company-links"
        >
          {t('company_caps')}
          {expandIcon(companyOpen)}
        </IconButton>
        <div
          className={`${styles.items} ${companyOpen ? styles.activeItems : ''}`}
        >
          <Company />
        </div>
        <div className={styles.socialIcons}>
          <Social />
        </div>
      </div>
    </div>
  );
};

export default FooterLinks;
