import React from 'react';
import { useSite, useSiteMap } from '@unisporkal/sites';
import { useTranslations, useLocale } from '@unisporkal/localization';
import { Link } from '@unisporkal/alliance-ui-link';
import useFooterPropsQuery from '../../../../../hooks/queries/useFooterPropsQuery';
import styles from '../FullFooter.module.scss';

const Company = () => {
  const t = useTranslations();
  const siteMap = useSiteMap();
  const site = useSite();
  const locale = useLocale();
  const {
    data: { countryCode },
  } = useFooterPropsQuery();

  return (
    <div data-testid="company">
      <Link
        className={styles.link}
        data-nav="foot_company_about_us"
        data-ui-location="footer"
        href={siteMap.gettyAboutUs()}
        target="company_information"
      >
        {t(locale === 'ja' ? 'company_information' : 'about_us')}
      </Link>
      <Link
        className={styles.link}
        data-nav="foot_company_corporate_responsibility"
        data-ui-location="footer"
        href={siteMap.corporateResponsibility()}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('corporate_responsibility')}
      </Link>
      <Link
        className={styles.link}
        data-nav="foot_company_careers"
        data-ui-location="footer"
        href={siteMap.careers()}
        target="careers"
      >
        {t('careers')}
      </Link>
      <Link
        className={styles.link}
        data-nav="foot_company_press_room"
        data-ui-location="footer"
        href={siteMap.press()}
        target="news_room"
      >
        {t('footer.newsroom')}
      </Link>
      {locale === 'en-us' || locale === 'en-gb' ? (
        <Link
          className={styles.link}
          data-nav="foot_company_investor"
          data-ui-location="footer"
          href={siteMap.investor()}
          target="investor"
          data-testid="investor-link"
        >
          Investor
        </Link>
      ) : null}
      <Link
        className={styles.link}
        data-nav="foot_company_grants_and_giving"
        data-ui-location="footer"
        href={siteMap.grants()}
        target="grants_and_giving"
      >
        {t('grants_and_giving')}
      </Link>
      <Link
        className={styles.link}
        data-nav="foot_company_iStock_photo"
        data-ui-location="footer"
        href={siteMap.istockCanonical()}
        target="istock"
      >
        {t('iStockphoto')}
      </Link>
      <Link
        className={styles.link}
        data-nav="foot_company_Unsplash"
        data-ui-location="footer"
        href={siteMap.unsplash()}
        target="unsplash"
      >
        Unsplash
      </Link>
      <Link
        className={styles.link}
        data-nav="foot_company_photos_dot_com"
        data-ui-location="footer"
        href={siteMap.photosDotCom()}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('photos_dot_com')}
      </Link>
      <Link
        className={styles.link}
        data-nav="foot_company_affiliates"
        data-ui-location="footer"
        href={siteMap.affiliates()}
        target="affiliates"
      >
        {t('footer.affiliates')}
      </Link>
      {countryCode === 'GBR' ? (
        <Link
          className={styles.link}
          data-nav="foot_company_modern_slavery_act"
          data-ui-location="footer"
          href={siteMap.modernSlavery()}
          target="modern_slavery"
          data-testid="modern-slavery"
        >
          {t('modern-slavery')}
        </Link>
      ) : null}
      <Link
        className={styles.link}
        data-nav="foot_general_contact_us"
        data-ui-location="footer"
        href={siteMap.customerSupport()}
        target="contact_us"
      >
        {t('help_center')}
      </Link>
      {site.tld === 'com' && locale === 'en-us' ? (
        <Link
          className={styles.link}
          data-nav="foot_company_events"
          data-ui-location="footer"
          href={siteMap.companyEvents()}
          target="company_events"
          data-testid="company-events"
        >
          Events and webinars
        </Link>
      ) : null}
    </div>
  );
};

export default Company;
