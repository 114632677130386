/* eslint-disable import/no-unresolved */
import React from 'react';
import { useSiteMap } from '@unisporkal/sites';
import styles from '../FullFooter.module.scss';
import FacebookLogo from '../../icons/facebook.svg?icon';
import TwitterLogo from '../../icons/twitter.svg?icon';
import InstagramLogo from '../../icons/instagram.svg?icon';

const Social = () => {
  const siteMap = useSiteMap();

  return (
    <div data-testid="social">
      <a
        className={styles.socialIconsLink}
        href={siteMap.twitter()}
        data-nav="foot_social_twitter"
        data-ui-location="footer"
        target="twitter"
      >
        <TwitterLogo className={styles.socialIcon} />
      </a>
      <a
        className={styles.socialIconsLink}
        href={siteMap.facebook()}
        data-nav="foot_social_facebook"
        data-ui-location="footer"
        target="facebook"
      >
        <FacebookLogo className={styles.socialIcon} />
      </a>
      <a
        className={styles.socialIconsLink}
        href={siteMap.instagram()}
        data-nav="foot_social_instagram"
        data-ui-location="footer"
        target="instagram"
      >
        <InstagramLogo className={styles.socialIcon} />
      </a>
    </div>
  );
};

export default Social;
