import React from 'react';
import PropTypes from 'prop-types';
import { useSiteMap } from '@unisporkal/sites';
import { useTranslations } from '@unisporkal/localization';
import { Link } from '@unisporkal/alliance-ui-link';
import styles from '../FullFooter.module.scss';

const Bottom = ({ showEuLegalLink, showUSPrivacyLink }) => {
  const t = useTranslations();
  const siteMap = useSiteMap();

  return (
    <div data-testid="bottom">
      <Link
        className={styles.item}
        data-nav="foot_general_website_terms"
        data-ui-location="footer"
        href={siteMap.termOfUse()}
        target="website_terms"
        rel="nofollow"
      >
        {t('website_terms')}
      </Link>
      <Link
        className={styles.item}
        data-nav="foot_general_editorial_policy"
        data-ui-location="footer"
        href={siteMap.editorialPolicy()}
        target="editorial_policy"
        rel="nofollow"
      >
        {t('editorial_policy')}
      </Link>
      <Link
        className={styles.item}
        data-nav="foot_general_privacy_policy"
        data-ui-location="footer"
        href={siteMap.privacyPolicy()}
        target="private_policy"
        rel="nofollow"
      >
        {t('privacy_policy')}
      </Link>
      <Link
        className={styles.item}
        data-nav="foot_general_license_information"
        data-ui-location="footer"
        href={siteMap.licenseInfo()}
        target="license_info"
        rel="nofollow"
      >
        {t('license_information')}
      </Link>
      {showEuLegalLink ? (
        <Link
          data-testid="eu-legal"
          className={styles.item}
          data-nav="foot_general_legal_information"
          data-ui-location="footer"
          href={siteMap.euLegal()}
          target="eu_legal"
          rel="nofollow"
        >
          {t('EULegal')}
        </Link>
      ) : null}
      {showUSPrivacyLink ? (
        <Link
          data-testid="us-legal-privacy"
          className={styles.item}
          data-nav="foot_general_legal_information"
          data-ui-location="footer"
          href={siteMap.privacyPolicy()}
          target="privacy_policy"
          rel="nofollow"
        >
          {t('do_not_sell_or_share_information')}
        </Link>
      ) : null}
      <Link
        className={styles.item}
        data-nav="foot_general_site_map"
        data-ui-location="footer"
        href={siteMap.sitemapPath()}
        target="aboutus_sitemap"
      >
        {t('site_map')}
      </Link>
    </div>
  );
};

Bottom.propTypes = {
  showEuLegalLink: PropTypes.bool.isRequired,
  showUSPrivacyLink: PropTypes.bool.isRequired,
};

export default Bottom;
